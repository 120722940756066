const COUNTRY_CODE =  [
    {
        keyindex:  0,
        value: "us",
        label: "us"
    },
    {
        keyindex:  1,
        value: "ca",
        label: "ca"
    },
    {
        keyindex:  2,
        value: "fr",
        label: "fr"
    }
]

module.exports = {data: COUNTRY_CODE}