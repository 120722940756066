const DURATION =  [
    {
        keyindex:  0,
        value: 15,
        label: ":15"
    },
    {
        keyindex:  1,
        value: 30,
        label: ":30"
    }
]

module.exports = {data: DURATION}